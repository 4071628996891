.carouselHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-8);

    > div > p {
        color: var(--display-onlight-secondary);
    }

    button {
        flex-shrink: 0;
    }
}

.templateCarousel {
    display: flex;
    flex-direction: column;
    margin-block-end: var(--space-48);

    .listContainer {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        inline-size: 100%;
        max-inline-size: 100%;
        scroll-padding-left: var(--space-24);
        padding-block: var(--space-4);

        > ol {
            display: flex;
            gap: var(--space-12);

            > li {
                scroll-snap-align: start;
                min-inline-size: 258px;
                max-inline-size: 258px;

                @media (--screen-lt-lg) {
                    min-inline-size: 295px;
                    max-inline-size: 295px;
                }
            }
        }
    }

    .carousel {
        position: relative;

        ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
    }

    .controls {
        position: absolute;
        top: calc(-1 * var(--space-4));
        bottom: calc(-1 * var(--space-4));

        transition: opacity var(--animation-duration-150) var(--animation-ease-base);
        opacity: 1;
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }

        .buttonContainer {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -17px;
            inline-size: 36px;

            > button {
                position: absolute;
                top: 43%;
                background-color: rgba(238, 236, 235, 1);
                box-shadow: 0px 0px 0px 1px rgba(37, 34, 30, 0.04);
            }

            @media (--screen-lt-sm) {
                display: none;
            }
        }

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        @media (--screen-lt-sm) {
            display: none;
        }
    }

    .dotIndicators {
        align-self: center;
    }

    &.setup .listContainer > ol > li {
        min-inline-size: 365px;
        max-inline-size: 365px;

        @media (--screen-lt-md) {
            min-inline-size: 280px;
            max-inline-size: 280px;
        }
    }

    max-inline-size: 800px;
    @media (--screen-lt-xlg) {
        max-inline-size: 624px;
    }
    @media (--screen-lt-lg) {
        max-inline-size: 720px;
    }

    @media (--screen-lt-sm) {
        .carousel {
            inline-size: calc(100% + var(--space-48));
            margin-inline-start: calc(-1 * var(--space-24));
        }
        .listContainer {
            inline-size: calc(100% + var(--space-24));
            > ol::before,
            > ol::after {
                content: '';
                padding-inline-end: var(--space-16);
            }
        }
    }
}

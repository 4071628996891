.categories {
    --column-gap: var(--space-12);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: var(--space-12);
    column-gap: var(--column-gap);
    padding: var(--space-16);

    li {
        a {
            padding: 0.625rem 1.125rem;
        }

        &:not(:last-child)::after {
            content: '';
            display: inline-block;
            block-size: 1.5rem;
            inline-size: 1px;
            background: var(--ui-border);
            margin-inline-start: var(--column-gap);
            transform: translateY(7px);
        }
    }

    @media (--screen-lt-md) {
        --column-gap: 0;
        padding: var(--space-12);
    }
}

.illustrationWrapper {
    position: relative;
    max-inline-size: 51.4375rem;
    margin-inline: auto;
    margin-block-end: var(--space-48);

    .text {
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10.25rem;

        @media (--screen-lt-lg) {
            font-size: 9rem;
        }

        @media (--screen-lt-md) {
            font-size: 5.4rem;
        }

        @media (--screen-lt-sm) {
            font-size: 17.5vw;
        }
    }

    img {
        inline-size: 100%;
        height: auto;
    }

    @media (--screen-lt-md) {
        margin-block-end: var(--space-32);
    }
}

.foreground {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
}

.cardContainer {
    block-size: 100%;

    .setupCard {
        display: flex;
        position: relative;
        block-size: 100%;
        min-inline-size: 310px;

        .image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-inline-size: 130px;
            background-color: var(--bg-color, white);
            border-top-left-radius: var(--card-border-radius);
            border-bottom-left-radius: var(--card-border-radius);

            img {
                object-fit: cover;
            }

            > hr {
                position: absolute;
                inline-size: 1px;
                block-size: 100%;
                top: 0;
                right: 0;
                bottom: 0;
            }
        }

        .info {
            .link {
                outline: 0;

                &:hover {
                    text-decoration: none;
                }

                &::after {
                    position: absolute;
                    content: '';
                    inset: 0;
                }
            }

            .description {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                color: var(--display-onlight-secondary);
            }

            .details {
                display: flex;
                flex-direction: column;
                color: var(--display-onlight-secondary);
                gap: var(--space-8);

                .templateType {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .icon {
                        block-size: var(--space-24);
                        inline-size: var(--space-24);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            color: var(--display-onlight-tertiary);
                        }
                    }
                }

                .countList {
                    display: flex;
                    flex-direction: column;
                    gap: var(--space-4);

                    > div {
                        display: flex;
                        align-items: center;
                        inline-size: 100%;
                    }

                    .icon {
                        block-size: var(--space-24);
                        inline-size: var(--space-24);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            color: var(--display-onlight-tertiary);
                        }
                    }

                    p {
                        padding-inline-start: 6px;
                    }
                }
            }
        }
    }

    &.forceNarrow {
        container-name: narrowed;
        container-type: inline-size;
    }

    @media (--screen-lt-md) {
        container-name: narrowed;
        container-type: inline-size;
    }

    @container narrowed (min-width: 0px) {
        .setupCard {
            flex-direction: column;
            min-block-size: 290px;
            min-inline-size: unset;

            .image {
                min-inline-size: 100%;
                block-size: 125px;
                border-top-right-radius: var(--card-border-radius);
                border-bottom-left-radius: unset;

                > hr {
                    inline-size: 100%;
                    block-size: 1px;
                    top: unset;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            .info {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: space-between;

                .details {
                    flex-direction: row;

                    > hr {
                        block-size: var(--space-24);
                        inline-size: 1px;
                    }

                    .countList {
                        gap: var(--space-4);
                        flex-direction: row;
                    }

                    p {
                        padding-inline-start: 2px;
                    }
                }
            }
        }
    }
}

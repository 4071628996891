.markdown > div {
    p {
        color: var(--display-onlight-secondary);
        margin-block-end: var(--space-16);
    }

    a {
        color: var(--display-onlight-color-blue);
    }

    ul,
    ol {
        color: var(--display-onlight-secondary);
        padding-inline-start: var(--space-24);
        margin-block-end: var(--space-16);
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        margin-block-start: var(--space-32);
        margin-block-end: var(--space-8);
    }

    ::marker {
        color: var(--display-onlight-secondary);
    }

    ol > li {
        padding-left: 0.25em;
    }

    li + li,
    ul ul,
    ul ol,
    ol ol,
    ol ul {
        margin-block-start: var(--space-8);
    }
}

.creator {
    display: flex;
    align-items: center;
    gap: var(--space-16);

    img {
        border-radius: 50%;
        object-fit: cover;
    }
}

.moreResources {
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

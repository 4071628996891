.categories-sidebar_categoriesContainer__SIS1q {
    padding-block-end: var(--space-4);
}

    .categories-sidebar_categoriesContainer__SIS1q ul {
        padding-block-start: var(--space-12);
        display: grid;
        grid-gap: var(--space-2);
    }

.template-carousel_carouselHeader__Qm8ao {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-8);
}

    .template-carousel_carouselHeader__Qm8ao > div > p {
        color: var(--display-onlight-secondary);
    }

    .template-carousel_carouselHeader__Qm8ao button {
        flex-shrink: 0;
    }

.template-carousel_templateCarousel__2NL7a {
    display: flex;
    flex-direction: column;
    margin-block-end: var(--space-48);
}

.template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        inline-size: 100%;
        max-inline-size: 100%;
        scroll-padding-left: var(--space-24);
        padding-block: var(--space-4);
    }

.template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj > ol {
            display: flex;
            gap: var(--space-12);
        }

.template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj > ol > li {
                scroll-snap-align: start;
                min-inline-size: 258px;
                max-inline-size: 258px;
            }

@media (max-width: 959px) {

.template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj > ol > li {
                    min-inline-size: 295px;
                    max-inline-size: 295px
            }
                }

.template-carousel_templateCarousel__2NL7a .template-carousel_carousel__LNcVm {
        position: relative; /* IE and Edge */
    }

.template-carousel_templateCarousel__2NL7a .template-carousel_carousel__LNcVm ::-webkit-scrollbar {
            display: none;
        }

.template-carousel_templateCarousel__2NL7a .template-carousel_carousel__LNcVm {

        -ms-overflow-style: none;
        scrollbar-width: none;
}

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st {
        position: absolute;
        top: calc(-1 * var(--space-4));
        bottom: calc(-1 * var(--space-4));

        transition: opacity var(--animation-duration-150) var(--animation-ease-base);
        opacity: 1;
    }

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st.template-carousel_hidden__ivTum {
            opacity: 0;
            pointer-events: none;
        }

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st .template-carousel_buttonContainer__qzEn4 {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -17px;
            inline-size: 36px;
        }

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st .template-carousel_buttonContainer__qzEn4 > button {
                position: absolute;
                top: 43%;
                background-color: rgba(238, 236, 235, 1);
                box-shadow: 0px 0px 0px 1px rgba(37, 34, 30, 0.04);
            }

@media (max-width: 480px) {

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st .template-carousel_buttonContainer__qzEn4 {
                display: none
        }
            }

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st.template-carousel_left__RRKgB {
            left: 0;
        }

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st.template-carousel_right__Gh6vC {
            right: 0;
        }

@media (max-width: 480px) {

.template-carousel_templateCarousel__2NL7a .template-carousel_controls__Dh0st {
            display: none
    }
        }

.template-carousel_templateCarousel__2NL7a .template-carousel_dotIndicators__MkUgJ {
        align-self: center;
    }

.template-carousel_templateCarousel__2NL7a.template-carousel_setup__4k5kw .template-carousel_listContainer__vrwaj > ol > li {
        min-inline-size: 365px;
        max-inline-size: 365px;
    }

@media (max-width: 768px) {

.template-carousel_templateCarousel__2NL7a.template-carousel_setup__4k5kw .template-carousel_listContainer__vrwaj > ol > li {
            min-inline-size: 280px;
            max-inline-size: 280px
    }
        }

.template-carousel_templateCarousel__2NL7a {

    max-inline-size: 800px;
}

@media (max-width: 1120px) {

.template-carousel_templateCarousel__2NL7a {
        max-inline-size: 624px
}
    }

@media (max-width: 959px) {

.template-carousel_templateCarousel__2NL7a {
        max-inline-size: 720px
}
    }

@media (max-width: 480px) {
        .template-carousel_templateCarousel__2NL7a .template-carousel_carousel__LNcVm {
            inline-size: calc(100% + var(--space-48));
            margin-inline-start: calc(-1 * var(--space-24));
        }
        .template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj {
            inline-size: calc(100% + var(--space-24));
        }
            .template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj > ol::before,
            .template-carousel_templateCarousel__2NL7a .template-carousel_listContainer__vrwaj > ol::after {
                content: '';
                padding-inline-end: var(--space-16);
            }
    }

.prefooter_prefooterContainer__CUZ4R {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    padding-block-end: var(--space-128);
    padding-block-start: var(--space-192);
    background: linear-gradient(180deg, rgba(243, 245, 240, 0) 1.41%, var(--red-100) 43.97%);
}

    .prefooter_prefooterContainer__CUZ4R .prefooter_prefooter__eufBR {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--space-12) var(--space-64);
        text-align: center;
    }

    .prefooter_prefooterContainer__CUZ4R .prefooter_prefooter__eufBR h2 {
            margin-block-end: var(--space-8);
        }

    .prefooter_prefooterContainer__CUZ4R .prefooter_prefooter__eufBR h3 {
            margin-block-end: var(--space-24);
            max-inline-size: 560px;
            color: var(--display-onlight-secondary);
        }

    @media (max-width: 959px) {.prefooter_prefooterContainer__CUZ4R {
        padding-block-end: var(--space-96)
}
    }

.setup-card_cardContainer__SVfuA {
    block-size: 100%;
}

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau {
        display: flex;
        position: relative;
        block-size: 100%;
        min-inline-size: 310px;
    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-inline-size: 130px;
            background-color: var(--bg-color, white);
            border-top-left-radius: var(--card-border-radius);
            border-bottom-left-radius: var(--card-border-radius);
        }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC img {
                -o-object-fit: cover;
                   object-fit: cover;
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC > hr {
                position: absolute;
                inline-size: 1px;
                block-size: 100%;
                top: 0;
                right: 0;
                bottom: 0;
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_link___nA_z {
                outline: 0;
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_link___nA_z:hover {
                    text-decoration: none;
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_link___nA_z::after {
                    position: absolute;
                    content: '';
                    inset: 0;
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_description__408RF {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                color: var(--display-onlight-secondary);
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm {
                display: flex;
                flex-direction: column;
                color: var(--display-onlight-secondary);
                gap: var(--space-8);
            }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_templateType__ZOnil {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_templateType__ZOnil .setup-card_icon__C_0uU {
                        block-size: var(--space-24);
                        inline-size: var(--space-24);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_templateType__ZOnil .setup-card_icon__C_0uU svg {
                            color: var(--display-onlight-tertiary);
                        }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E {
                    display: flex;
                    flex-direction: column;
                    gap: var(--space-4);
                }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E > div {
                        display: flex;
                        align-items: center;
                        inline-size: 100%;
                    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E .setup-card_icon__C_0uU {
                        block-size: var(--space-24);
                        inline-size: var(--space-24);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E .setup-card_icon__C_0uU svg {
                            color: var(--display-onlight-tertiary);
                        }

    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E p {
                        padding-inline-start: 6px;
                    }

    .setup-card_cardContainer__SVfuA.setup-card_forceNarrow__ayCaZ {
        container-name: narrowed;
        container-type: inline-size;
    }

    @media (max-width: 768px) {.setup-card_cardContainer__SVfuA {
        container-name: narrowed;
        container-type: inline-size
}
    }

    @container narrowed (min-width: 0px) {
        .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau {
            flex-direction: column;
            min-block-size: 290px;
            min-inline-size: unset;
        }

            .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC {
                min-inline-size: 100%;
                block-size: 125px;
                border-top-right-radius: var(--card-border-radius);
                border-bottom-left-radius: 0;
                border-bottom-left-radius: initial;
            }

                .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_image__VUrYC > hr {
                    inline-size: 100%;
                    block-size: 1px;
                    top: auto;
                    top: initial;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

            .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E {
                display: flex;
                flex: 1 1;
                flex-direction: column;
                justify-content: space-between;
            }

                .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm {
                    flex-direction: row;
                }

                    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm > hr {
                        block-size: var(--space-24);
                        inline-size: 1px;
                    }

                    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm .setup-card_countList__9OO8E {
                        gap: var(--space-4);
                        flex-direction: row;
                    }

                    .setup-card_cardContainer__SVfuA .setup-card_setupCard__z0yau .setup-card_info__h3J_E .setup-card_details__EOzZm p {
                        padding-inline-start: 2px;
                    }
    }

.template-icon-card_accessoryView__prUcg {
    color: var(--display-onlight-secondary);
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.template-icon-card_icon__cm3N4 {
    block-size: var(--space-24);
    inline-size: var(--space-24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block-start: 1px;
}

.template-icon-card_icon__cm3N4 svg {
        color: var(--display-onlight-tertiary);
    }

.hyper-link_hyperLink__Vbujx {
    color: var(--display-onlight-color-blue);
}

.markup_markup__kNbux figure,
    .markup_markup__kNbux img {
        max-inline-size: 100%;
        block-size: auto;
    }
    .markup_markup__kNbux a {
        font-size: inherit;
    }

.image_figcaption__LTZqj {
    max-inline-size: 600px;
    margin: 0 auto;
}

.image_modalImage___Tn3w {
    max-inline-size: 100%;
    block-size: auto;
}

.mobile-list_mobileList__UMpz5 {
    padding-inline: var(--space-24);
    padding-block-end: var(--space-24);
    display: flex;
    flex-direction: column;
    gap: var(--space-12);
}

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC {
        position: relative;
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;
    }

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC .mobile-list_imageContainer__Lk7na {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: var(--card-border-radius);
            border-bottom-left-radius: var(--card-border-radius);
            border-right: 1px solid var(--box-outline-border);
            padding: var(--space-8);
        }

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC > a:hover {
                text-decoration: none;
            }

    .mobile-list_mobileList__UMpz5 .mobile-list_templateIconCard__oeAfC > a::after {
                position: absolute;
                content: '';
                inset: 0;
            }

.templates-section_heading__GUFVw {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

    .templates-section_heading__GUFVw > p {
        max-inline-size: 40.625rem; /* 650px */
        color: var(--display-onlight-secondary);
    }

@media (max-width: 768px) {
    .templates-section_heading__GUFVw {
        align-items: flex-start;
        text-align: left;
    }
}

.header_header__gAQ6S {
    display: flex;
    justify-content: space-between;
}

.header_search__C12ZL {
    max-inline-size: 28.125rem;
    margin-inline: auto;
    margin-block-start: var(--space-24);
    margin-block-end: var(--space-32);
}

.header_searchIllustration__tZSq5 {
    display: block;
    margin-inline: auto;
    margin-block-start: var(--space-64);
}

.styles_noResults__uyqwI {
    padding-block-start: var(--space-48);
    text-align: center;
}

.hero_categories__3qTwv {
    --column-gap: var(--space-12);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: var(--space-12);
    -moz-column-gap: var(--column-gap);
         column-gap: var(--column-gap);
    padding: var(--space-16);
}

    .hero_categories__3qTwv li a {
            padding: 0.625rem 1.125rem;
        }

    .hero_categories__3qTwv li:not(:last-child)::after {
            content: '';
            display: inline-block;
            block-size: 1.5rem;
            inline-size: 1px;
            background: var(--ui-border);
            margin-inline-start: var(--column-gap);
            transform: translateY(7px);
        }

    @media (max-width: 768px) {.hero_categories__3qTwv {
        --column-gap: 0;
        padding: var(--space-12)
}
    }

.hero_illustrationWrapper__bmvPU {
    position: relative;
    max-inline-size: 51.4375rem;
    margin-inline: auto;
    margin-block-end: var(--space-48);
}

.hero_illustrationWrapper__bmvPU .hero_text__5R3xS {
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10.25rem;
    }

@media (max-width: 959px) {

.hero_illustrationWrapper__bmvPU .hero_text__5R3xS {
            font-size: 9rem
    }
        }

@media (max-width: 768px) {

.hero_illustrationWrapper__bmvPU .hero_text__5R3xS {
            font-size: 5.4rem
    }
        }

@media (max-width: 480px) {

.hero_illustrationWrapper__bmvPU .hero_text__5R3xS {
            font-size: 17.5vw
    }
        }

.hero_illustrationWrapper__bmvPU img {
        inline-size: 100%;
        height: auto;
    }

@media (max-width: 768px) {

.hero_illustrationWrapper__bmvPU {
        margin-block-end: var(--space-32)
}
    }

.hero_foreground__O_4GB {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
}

.random-quote_figure__5YGPq {
    position: relative;
    margin: 0;
    padding: var(--space-48) 0 var(--space-128);
    text-align: center;
}

    @media (max-width: 768px) {.random-quote_figure__5YGPq {
        padding-block-end: 0
}
    }

    .random-quote_figure__5YGPq blockquote {
        max-inline-size: 50rem;
        margin-inline: auto;
    }

.random-quote_illustration__qp3gi {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    max-inline-size: 76.4375rem;
    inline-size: 100%;
    height: auto;
}

@media (max-width: 768px) {

.random-quote_illustration__qp3gi {
        position: relative
}
    }

.masonry_masonry__6uGUO {
    --column-width: minmax(min(18rem, 100%), 1fr);
    --spacing: var(--space-12);

    display: grid;
    grid-template-columns: repeat(auto-fit, var(--column-width));
    grid-template-rows: masonry;
    justify-content: center;
    grid-gap: var(--spacing);
}

    .masonry_masonry__6uGUO > * {
        inline-size: var(--column-width);
        align-self: start;
    }

.side-details_markdown__ud79X > div p {
        color: var(--display-onlight-secondary);
        margin-block-end: var(--space-16);
    }
    .side-details_markdown__ud79X > div a {
        color: var(--display-onlight-color-blue);
    }
    .side-details_markdown__ud79X > div ul,
    .side-details_markdown__ud79X > div ol {
        color: var(--display-onlight-secondary);
        padding-inline-start: var(--space-24);
        margin-block-end: var(--space-16);
    }
    .side-details_markdown__ud79X > div ul {
        list-style: disc;
    }
    .side-details_markdown__ud79X > div ol {
        list-style: decimal;
    }
    .side-details_markdown__ud79X > div h3 {
        font-size: 18px;
        font-weight: 600;
        margin-block-start: var(--space-32);
        margin-block-end: var(--space-8);
    }
    .side-details_markdown__ud79X > div ::marker {
        color: var(--display-onlight-secondary);
    }
    .side-details_markdown__ud79X > div ol > li {
        padding-left: 0.25em;
    }
    .side-details_markdown__ud79X > div li + li,
    .side-details_markdown__ud79X > div ul ul,
    .side-details_markdown__ud79X > div ul ol,
    .side-details_markdown__ud79X > div ol ol,
    .side-details_markdown__ud79X > div ol ul {
        margin-block-start: var(--space-8);
    }

.side-details_creator__21mDk {
    display: flex;
    align-items: center;
    gap: var(--space-16);
}

.side-details_creator__21mDk img {
        border-radius: 50%;
        -o-object-fit: cover;
           object-fit: cover;
    }

.side-details_moreResources__2tcdw ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

.side-details_colorSecondary__131cH {
    color: var(--display-onlight-secondary);
}

